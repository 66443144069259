import styles from './WatermarkTextOptions.module.css';
import { WatermarkImageOptions as WatermarkImageOptionsSchema } from '@he-novation/config/types/watermark.types';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';

import { useTranslate } from '$hooks/useTranslate';

type WatermarkImageOptionsProps = {
    options?: WatermarkImageOptionsSchema;
    submit: () => void;
    prefix: string;
};

export function WatermarkImageOptions({ options, submit, prefix }: WatermarkImageOptionsProps) {
    const { t } = useTranslate();

    const fits = ['cover', '20', '50', 'original'] as const;

    return (
        <div className={styles.wrapper}>
            <div className={styles.row}>
                <FormField
                    type="select"
                    formId={'folder-settings-form'}
                    label={t('settings.Size')}
                    name={`${prefix}.fit`}
                    value={options?.fit || 'cover'}
                    onChange={submit}
                >
                    {fits.map((fit) => (
                        <option value={fit} key={fit}>
                            {fit + (['20', '50'].includes(fit) ? '%' : '')}
                        </option>
                    ))}
                </FormField>

                <FormField
                    type="select"
                    formId={'folder-settings-form'}
                    label={t('settings.Opacity')}
                    name={`${prefix}.opacity`}
                    value={options?.opacity || 1}
                    onChange={submit}
                >
                    {Array<null>(10)
                        .fill(null)
                        .map((_, i) => (
                            <option value={(i + 1) / 10} key={i}>
                                {(i + 1) * 10}%
                            </option>
                        ))}
                </FormField>
            </div>
            {options && options.fit && options.fit !== 'cover' && (
                <div className={styles.row}>
                    <div className={styles.group}>
                        <FormField
                            innerClassName={styles.tabs}
                            type="radio-toggle"
                            formId="folder-settings-form"
                            name={`${prefix}.alignment.x`}
                            options={['left', 'center', 'right'].map((h) => ({
                                label: (
                                    <Icon
                                        icon={
                                            ['left', 'right'].includes(h)
                                                ? 'arrow_align_right'
                                                : 'arrows_center'
                                        }
                                        stroke="white"
                                        className={
                                            h === 'left'
                                                ? styles.rotate180
                                                : h === 'center'
                                                  ? styles.rotate90
                                                  : undefined
                                        }
                                    />
                                ),
                                value: h
                            }))}
                            value={options?.alignment?.x || 'center'}
                            onChange={submit}
                        />
                    </div>

                    <div className={styles.group}>
                        <FormField
                            innerClassName={styles.tabs}
                            type="radio-toggle"
                            formId="folder-settings-form"
                            name={`${prefix}.alignment.y`}
                            options={['top', 'center', 'bottom'].map((v) => ({
                                label: (
                                    <Icon
                                        icon={
                                            ['top', 'bottom'].includes(v)
                                                ? 'arrow_align_right'
                                                : 'arrows_center'
                                        }
                                        stroke="white"
                                        className={
                                            v === 'top'
                                                ? styles.rotate270
                                                : v === 'bottom'
                                                  ? styles.rotate90
                                                  : undefined
                                        }
                                    />
                                ),
                                value: v
                            }))}
                            value={options?.alignment?.y || 'center'}
                            onChange={submit}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
